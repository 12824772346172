/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
/* eslint-disable func-names */

export function CustomDateFloatingComponent() {}

CustomDateFloatingComponent.prototype.init = function(params) {
   const template =
      `<input type="text" data-input style="width: 100%; padding-right: 32px; font-size: 12px" /><a class="input-button" title="clear" style="color: hsla(0,0%,96%,.64);" data-clear>X</a>`;

   this.params = params;

   this.eGui = document.createElement("div");

   const {eGui} = this;

   eGui.setAttribute("role", "presentation");
   eGui.classList.add("ag-input-wrapper");
   eGui.classList.add("custom-date-filter");
   eGui.innerHTML = template;

   this.eInput = eGui.querySelector("input");

   this.picker = flatpickr(this.eGui, {
      onChange: this.onDateChanged.bind(this),
      dateFormat: "Y-m-d",
      wrap: true,
      // formatDate: (date, format, locale) => {
      //    console.log(date, format, locale);
      //    return "2021.11.22";
      // }
      plugins: [
         ShortcutButtonsPlugin({
            button: [
               { label: "Today" }
            ],
            // label: "or",
            onClick: (index, fp) => {
               const now = new Date();
               now.setHours(0);
               now.setMinutes(0);
               now.setSeconds(0);
               now.setMilliseconds(0);
               // const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
               // console.log("now:", now, "date:", date, now.getFullYear(), now.getMonth(), now.getDate());
               fp.setDate(now);
               fp.close();
               CustomDateFloatingComponent.prototype.onDateChanged.call(this, [now, now]);
            }
         })
      ]
   });

   this.picker.calendarContainer.classList.add("ag-custom-component-popup");
};

CustomDateFloatingComponent.prototype.getGui = function() {
   return this.eGui;
};

// Floating필터에서 데이터 변경시 이벤트
CustomDateFloatingComponent.prototype.onDateChanged = function(selectedDates = []) {
   console.log(selectedDates);
   this.params.parentFilterInstance((instance) => {
      let { appliedModel } = instance;
      let change = true;

      // 필터가 적용 안되어있을때 기본값
      if(!appliedModel) appliedModel = { filterType: "date", type: "equals" };
      const [dateFrom, dateTo] = selectedDates;

      appliedModel.dateFrom = dateFrom || null;
      appliedModel.dateTo = dateTo || null;
      if(dateFrom === dateTo) appliedModel.type = "equals"; // today 처리

      if(appliedModel.type === "inRelativeRange" || appliedModel.type === "inRange") {
         // inRelativeRange 상태에서 picker로 날짜 지정을 했을경우 inRange상태로 변경
         appliedModel.type = "inRange";

         // range 선택의 경우 첫번째 선택의 경우에는 change를 시키지 않음.
         const { length } = selectedDates;
         if(length > 0 && length < 2) change = false;
      }

      if(change) {
         // popup쪽 필터 세팅 ( 세팅후 onParentModelChanged 이벤트 발생 )
         instance.eType1.setValue(appliedModel.type);
         instance.dateCondition1FromComp.setDate(appliedModel.dateFrom);
         instance.dateCondition1ToComp.setDate(appliedModel.dateTo);
         instance.onUiChanged(true);
      }
   });
};

CustomDateFloatingComponent.prototype.getDate = function() {
   return this.picker.getDate();
};

CustomDateFloatingComponent.prototype.setDate = function(date) {
   this.picker.setDate(date);
   this.picker.input.selectionStart = 0;
   this.picker.input.selectionEnd = 0;
   this.picker.input.blur();
};

CustomDateFloatingComponent.prototype.setInputPlaceholder = function(placeholder) {
   this.eInput.setAttribute("placeholder", placeholder);
};

// Popup에서 필터 적용시 이벤트
CustomDateFloatingComponent.prototype.onParentModelChanged = function(parentModel) {
   // clear 적용시
   if(!parentModel) {
      this.setDate([]);
      return;
   }

   const { type, dateFrom, dateTo } = parentModel;
   this.type = type;

   switch (type) {
   case "inRange" :
   case "inRelativeRange" :
      this.picker.config.mode = "range";
      this.setDate([dateFrom || null, dateTo || null]);
      break;
   default:
      this.picker.config.mode = "single";
      this.setDate([dateFrom || null]);
      break;
   }
};
