/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
/* eslint-disable func-names */

export function CustomDateComponent() {}

CustomDateComponent.prototype.init = function(params) {
   const template =
      `<input type="text" data-input style="width: 100%;" /><a class="input-button" title="clear" style="color: hsla(0,0%,96%,.64);" data-clear>X</a>`;

   this.params = params;

   this.eGui = document.createElement("div");

   const {eGui} = this;

   eGui.setAttribute("role", "presentation");
   eGui.classList.add("ag-input-wrapper");
   eGui.classList.add("custom-date-filter");
   eGui.innerHTML = template;

   this.eInput = eGui.querySelector("input");

   this.picker = flatpickr(this.eGui, {
      onChange: this.onDateChanged.bind(this),
      dateFormat: "Y-m-d",
      wrap: true,
      // formatDate: (date, format, locale) => {
      //    console.log(date, format, locale);
      //    return "2021.11.22";
      // }
      plugins: [
         ShortcutButtonsPlugin({
            button: [
               { label: "Today" }
            ],
            // label: "or",
            onClick: (index, fp) => {
               const now = new Date();
               now.setHours(0);
               now.setMinutes(0);
               now.setSeconds(0);
               now.setMilliseconds(0);
               // const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
               // console.log("now:", now, "date:", date, now.getFullYear(), now.getMonth(), now.getDate());
               fp.setDate(now);
               fp.close();
               CustomDateComponent.prototype.onDateChanged.call(this, [now]);
            }
         })
      ]
   });

   this.picker.calendarContainer.classList.add("ag-custom-component-popup");

   this.date = null;
};

CustomDateComponent.prototype.getGui = function() {
   return this.eGui;
};

CustomDateComponent.prototype.onDateChanged = function(selectedDates) {
   // console.log("onDataChanged", selectedDates);
   this.date = selectedDates[0] || null;
   this.params.onDateChanged();
};

CustomDateComponent.prototype.getDate = function() {
   return this.date;
};

CustomDateComponent.prototype.setDate = function(date) {
   this.picker.setDate(date);
   this.date = date;
};

CustomDateComponent.prototype.setInputPlaceholder = function(placeholder) {
   this.eInput.setAttribute("placeholder", placeholder);
};
